<template>
  <div class="mt-5">
    <rm-request-table></rm-request-table>
  </div>
</template>

<script>
import RmRequestTable from "./components/rmRequestTable";
export default {
  components: {
    RmRequestTable,
  },
};
</script>
